import axios from 'axios';
import appNotification, {notificationsTypes} from '../../util/appNotification';

const api = axios.create({
  baseURL: process.env.VUE_APP_STRIPE_BASE_URL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization': `Bearer ${process.env.VUE_APP_STRIPE_API_TOKEN}`,
  },
});

api.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const message = error.response.data.error.message || 'Server error';
    appNotification.notify({message, type: notificationsTypes.danger});
    return Promise.reject(error);
  },
);

export const createCardTokenStripeApi = ({number, expMonth, expYear, cvc}) => {
  const params = new URLSearchParams();
  params.append('card[number]', number);
  params.append('card[exp_month]', expMonth);
  params.append('card[exp_year]', expYear);
  params.append('card[cvc]', cvc);

  return api.post('/tokens', params);
};

export default api;
