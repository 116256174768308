<template>
  <card>
    <template slot="header">
      <h3 class="mb-0">
        <template>
          <template v-if="currentPlan">Current plan</template>
          <template v-else>Welcome, pick a plan to get started!</template>
        </template>
      </h3>
    </template>
    <template>
      <div class="d-flex flex-column">
        <plan-card
          v-if="currentPlan"
          :accountType="currentPlan.accountType"
          :clarification="currentPlan.clarification"
          :description="currentPlan.description"
          :isSelected="true"
          :price="getCurrentPrice()"
          :requirements="filteredRequirements"
          :subDescription="currentPlan.subDescription"
          :title="currentPlan.billingTitle || currentPlan.title"
        />
      </div>
      <div class="d-flex justify-content-end mt-3">
        <base-button type="primary"
                     @click="$router.push({name: 'Plan Selection', params: { backLink: 'Billing' }})">
          {{ currentPlan ? 'Change plan' : 'Select plan' }}
        </base-button>
      </div>
    </template>
  </card>
</template>
<script>
import PlanCard from '@/components/App/User/SelectPlan/PlanCard.vue';
import {mapActions, mapGetters} from 'vuex';
import {FETCH_PLANS_REQUEST} from '@/store/storeActions';

export default {
  components: {
    PlanCard,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      'currentPlan',
      'plansLoaded',
      'planType',
    ]),
    filteredRequirements() {
      return this.currentPlan.requirements.filter((req) => req.planType === this.selectedPlanType || !req.planType).
        map((req) => req.title);
    },
  },
  async mounted() {
    !this.plansLoaded && await this.FETCH_PLANS_REQUEST();
  },
  methods: {
    ...mapActions([
      FETCH_PLANS_REQUEST,
    ]),
    getCurrentPrice: function() {
      return this.planType === 'annual' ? this.currentPlan.annualPrice : this.currentPlan.monthlyPrice;
    },
  },
};
</script>
