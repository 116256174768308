<template>
  <div>
    <modal
      :show="display"
      footerClasses="d-none"
      headerClasses="app-border-b align-items-center"
      v-on:close="displayToggle(false)"
    >
      <template v-slot:header>
        <h5 id="exampleModalLabel" class="modal-title">Credit card</h5>
      </template>
      <div>
        <app-credit-card v-model="card"
                         :currLast4="creditCardLast4"
                         @isCardVaid="isCardVaid"
        />
      </div>
      <div class="d-flex justify-content-end">
        <base-button :disabled="!isValid" type="primary" @click="submit">Save</base-button>
      </div>
    </modal>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import {INVITE_USER_REQUEST, PURCHASE_SEATS_REQUEST, UPDATE_CREDIT_CARD_REQUEST} from '@/store/storeActions';
import {mask} from 'vue-the-mask';
import {parseCardExpiryDate} from '@/util/appTime';
import AppCreditCard from '@/components/App/User/AppCreditCard/AppCreditCard.vue';
import {createCardTokenStripeApi} from '@/api/thirdParty/stripeApi';

export default {
  components: {
    AppCreditCard,
  },
  directives: {
    mask,
  },
  data() {
    return {
      display: false,
      name: '',
      cardNumber: '',
      last4: '',
      ccv: '',
      expiryDate: '',
      cardLoaded: false,
      card: {},
      isValid: false,
    };
  },
  computed: {
    ...mapGetters([
      'creditCardLast4',
      'creditCardExpMonth',
      'creditCardExpYear',
    ]),
    calculateCurrExpiration: function() {
      return (this.creditCardExpMonth && this.creditCardExpYear) ?
        (this.creditCardExpMonth < 10 ? '0' + this.creditCardExpMonth : this.creditCardExpMonth) +
        ' / ' + this.creditCardExpYear.toString().substr(2, 2) : '';
    },
  },
  watch: {
    creditCard: function() {
      this.setDataFromStore();
    },
  },
  async mounted() {
    this.setDataFromStore();
  },
  methods: {
    ...mapActions([
      INVITE_USER_REQUEST,
      PURCHASE_SEATS_REQUEST,
      UPDATE_CREDIT_CARD_REQUEST,
    ]),
    displayToggle: function(isDisplay) {
      this.display = isDisplay;
      !isDisplay && (this.email = '');
    },
    setDataFromStore: function() {
      if (!!this.creditCard && !this.cardLoaded) {
        this.name = this.creditCardName;
        this.last4 = this.creditCardLast4;
        this.expiryDate = parseCardExpiryDate(this.creditCardExpMonth, this.creditCardExpYear);
        this.cardLoaded = true;
      }
    },
    isCardVaid: function(isValid) {
      this.isValid = isValid;
    },
    submit: async function() {
      if (!this.isValid) {
        return;
      }
      const payload = {
        number: this.card.number,
        expMonth: parseInt(this.card.expiration.split('/')[0].trim()),
        expYear: parseInt(this.card.expiration.split('/')[1].trim()),
        cvc: this.card.cvc,
      };
      this.displayToggle(false);
      const res = await createCardTokenStripeApi(payload);
      if (res && res.id) {
        this.UPDATE_CREDIT_CARD_REQUEST({cardTokenId: res.id});
      }
    },
  },
};
</script>
<style scoped>
.btns {
  width: 80px;
}

.seats {
  width: 80px;
}

.apply-button {
  margin-top: 32px;
}
</style>
