<template>
  <card bodyClasses="p-0">
    <template slot="header">
      <h3 class="mb-0">Payment history</h3>
    </template>
    <el-table
      :data="invoices"
      class="table-responsive table-flush mb-4"
      header-row-class-name="thead-light"
      height="50rem"
    >
      <el-table-column label="Receipt number" prop="receiptNumber">
        <template v-slot="{ row }">
          {{ row.receiptNumber }}
        </template>
      </el-table-column>
      <el-table-column :sort-method="sortColumnByDate" label="Date paid" prop="datePaid" sortable>
        <template v-slot="{ row }">
          {{ row.datePaid | formatDate }}
        </template>
      </el-table-column>
      <el-table-column label="Amount paid" prop="amountPaid" sortable>
        <template v-slot="{ row }">
          {{ row.amountPaid }}
        </template>
      </el-table-column>
      <el-table-column label="Details" prop="planDetails">
        <template v-slot="{ row }">
          <a :href="row.receiptUrl" alt="Download Receipt" download target="_blank"><i class="ni ni-download"></i></a>
        </template>
      </el-table-column>
    </el-table>
  </card>
</template>
<script>
import {mapGetters} from 'vuex';
import {Table, TableColumn} from 'element-ui';

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  computed: {
    ...mapGetters([
      'invoices',
    ]),
  },
  methods: {
    sortColumnByDate(prev, next) {
      return new Date(next.datePaid).getTime() - new Date(prev.datePaid).getTime();
    },
  },
};
</script>
